
import Vue, { VueConstructor } from "vue";

import { mapGetters, mapMutations } from "vuex"; // mapState

import CrudMixins from "@/mixins/crud";
import GrowingForm from "./forms/GrowingForm.vue";
import { IFormFuncs, ItemDataTable } from "@/types/form-types";
import Growing from "@/models/growing";
import Unit from "@/models/unit";

export default (
  Vue as VueConstructor<Vue & InstanceType<typeof CrudMixins>>
).extend({
  name: "GrowingAdmin",
  components: {
    GrowingForm,
  },
  mixins: [CrudMixins],
  data() {
    return {
      dialog: false,
      growing_headers: [
        { text: "Nome", value: "name", align: "center" },
        { text: "Simbolo", value: "symbol", align: "center" },
        { text: "Unidade Padrão", value: "unit_id", align: "center" },
        { text: "Data de Criação", value: "created_at", align: "center" },
        { text: "Cultura Deletada", value: "is_deleted", align: "center" },
        { text: "Data de Delete", value: "deleted_date", align: "center" },
        { text: "Ações", value: "actions", align: "center" },
      ] as ItemDataTable[],
    };
  },
  methods: {
    ...mapMutations(["updateAdminResources"]),
    editGrowing(ugrowing: Growing): void {
      const form = this.$refs["form"] as IFormFuncs<Growing>;
      if (form && form.toUpdate) form.toUpdate(ugrowing);
    },
    convertUnitId(id: number): string {
      return (
        this.allUnits.find((el: Unit) => el.id === id).symbol +
        " - " +
        this.allUnits.find((el: Unit) => el.id === id).name
      );
    },
    cancel(): void {
      this.$router.push({ path: "/admin/sysmanager" });
    },
  },
  computed: {
    ...mapGetters({
      allGrowings: "getAllGrowings",
      allUnits: "getAllUnits",
    }),
  },
});
