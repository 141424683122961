
import Vue, { VueConstructor } from "vue";
import { mapGetters } from "vuex";
import { crudService } from "@/services/crud";

import FormMixins from "@/mixins/form";
import Growing from "@/models/growing";

export default (
  Vue as VueConstructor<Vue & InstanceType<typeof FormMixins>>
).extend({
  props: {
    gColor: String,
  },
  mixins: [FormMixins],
  data() {
    return {
      growing: new Growing(),
      valid: false,
      localRules: {
        counter: (value: string) => value?.length <= 3 || "Max 3 caracteres",
      },
    };
  },
  created() {
    this.initialize();
  },
  methods: {
    initialize(): void {
      this.growing = new Growing();
    },
    async save(): Promise<void> {
      if (this.validate()) {
        try {
          const growing = await crudService.saveClass(
            Growing,
            Growing.getResource(),
            this.growing
          );
          this.$alertSuccess(this.componentsText.mSuccess);
          this.$emit("updateResourceList", growing);
          this.close();
        } catch (error) {
          this.$alertError(this.componentsText.mError);
          console.error(error);
        }
      }
    },
    toUpdate(item: Growing): void {
      this.show = true;
      this.$nextTick(() => {
        this.growing = new Growing(item);
      });
    },
  },
  computed: {
    ...mapGetters({
      allUnits: "getAllUnits",
    }),
    componentsText() {
      if (this.growing?.id) {
        return {
          buttonText: "Atualizar",
          mSuccess: "Cultura atualizado com sucesso!",
          mError: "Houve um problema ao atualizar o Cultura",
        };
      } else {
        return {
          buttonText: "Adicionar",
          mSuccess: "Cultura inserido com sucesso!",
          mError: "Houve um problema ao inserir o Cultura",
        };
      }
    },
  },
});
